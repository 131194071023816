
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class JuryMember extends Vue {
  @Prop({ required: true, type: String }) public name!: string;

  get image() {
    return {
      src: require(`@/assets/jury/${this.name}.jpg`),
      extended: require(`@/assets/jury/${this.name}@2x.jpg`),
    }
  }
  get fullName() {
    return String(this.$t(`Jury.members.${this.name}.name`)).split(' ');
  }
}
