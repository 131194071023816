








import { Component, Vue } from 'vue-property-decorator';
import JuryMember from '@/components/JuryMember.vue';
import AnimaArticle from '@/components/AnimaArticle.vue';

@Component({
  components: {
    JuryMember,
    AnimaArticle,
  },
})
export default class Jury extends Vue {
  public juryArray = [
    'flaminiaGiorda',
    'marinaLoshak',
    'teresaIarocciMavica',
    'dariaPushkova',
    'danielaRizzi',
  ]
}
